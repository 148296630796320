<template>
  <v-layout style="margin: 0px; padding: 0px; width: 100%;">
    <v-row>
      <v-col col="12" md="6">
        <v-row wrap>
          <v-col cols="8" offset="2">
            <v-row>
              <v-col cols="10">
                <router-link to="/" style="text-decoration: none;color: black;">
                  <v-icon>mdi-arrow-left-circle-outline</v-icon>
                  <span class="ml-5"> Return to Home </span>
                </router-link>
              </v-col>
            </v-row>

            <v-row style="margin-top:100px;">
              <v-col cols="12" align="center">
                <v-img
                  class="centerImg2"
                  :src="'img/logo.png'"
                />
                <span class="titleStyleColor"
                  >Email Confirmed Successfully</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col col="12" align="center" justify="center">
                    <router-link
                      to="/login"
                      style="text-decoration: none;color: black;"
                    >
                      <v-btn align="center" class="buttonStyle">
                        Click to Login
                      </v-btn>
                    </router-link>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col col="12" md="6" class="loginPadding hidden-sm-and-down">
        <div class="overlay"></div>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  name: "VerificationSuccess",

  data: () => ({
    loader: false,
  }),
  methods: {
    signin() {},
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.loginPadding {
  margin: 0px !important;
  padding: 0px !important;
  background: url("https://res.cloudinary.com/ecrtechdev/image/upload/v1632485906/loginImage_blv0va.png")
    no-repeat center right fixed !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.titleStyle {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerImg2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
}

.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
}
</style>
